/*  VARIABLES */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;1,300;1,400&display=swap');

:root {
  --color-primary: 69, 114, 212;
  --color-secondary: 112, 112, 112;
  --color-light: 255, 255, 255;
  --color-med-gray: 186, 186, 186;
  --color-dark: 110, 110, 110;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  height: -webkit-fill-available;
  color: rgb(var(--color-dark));
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  height: -webkit-fill-available;
  background-color: rgb(255, 255, 255);
}

// @media (min-width: 900px) {
//   body {
//     background: rgb(194, 194, 194);
//     background: -moz-linear-gradient(180deg, rgba(194, 194, 194, 1) 0%, rgba(239, 244, 247, 1) 50%, rgba(170, 170, 170, 1) 100%);
//     background: -webkit-linear-gradient(180deg, rgba(194, 194, 194, 1) 0%, rgba(239, 244, 247, 1) 50%, rgba(170, 170, 170, 1) 100%);
//     background: linear-gradient(180deg, rgba(194, 194, 194, 1) 0%, rgba(239, 244, 247, 1) 50%, rgba(170, 170, 170, 1) 100%);
//     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c2c2c2",endColorstr="#aaaaaa",GradientType=1);
//   }
// }

#root {
}
